<template>
  <div class="check-campaign-container" >
    <div v-if="luckydraw != null">
      <div class="banner mb-3">
        <img :src="luckydraw.imageUrl" height="180px" width="100%" />
      </div>
      <div class="remain-container mb-3">
        <div>จำนวนสิทธิ์ลุ้นรางวัล<br />รอบปัจจุบัน</div>
        <div>
          <span>{{ remain }}</span> สิทธิ์
        </div>
      </div>
    </div>
    <div class="table-container">
      <div class="title">
        <b>ประวัติการได้รับสิทธิ์</b>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">วันที่</th>
            <th scope="col">รายละเอียด</th>
            <th scope="col">สถานะสิทธิ์</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in checkList" :key="index">
            <td>{{ new Date(item.ticketDate).toLocaleDateString() }}</td>
            <td>{{ `ใบเสร็จ ${item.ref}` }}</td>
            <td>
              <button
                class="btn"
                @click="_click(item)"
                :disabled="item.transactionId!=null"
              >
                {{ item.transactionId ==null ? "กดรับสิทธิ์" : "แลกสิทธิ์แล้ว" }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="checkList.length ==0" class="blank-content">ไม่พบข้อมูล</div>
    </div>
    <b-modal id="campaign"  scrollable centered>
      <template v-slot:modal-header-close>
        <i class="close-modal icon-cancel-circled-outline" />
      </template>
      <div class="d-block">
        <h6>Oishi Lucky Trip ลุ้นโชคทริปญี่ปุ่นกับโออิชิ</h6>
        <p><b>รางวัล :</b> แพ็คเกจท่องเที่ยวญี่ปุ่นพร้อมเครื่องบินไปกลับ ประเทศญี่ปุ่น จำนวน 5 รางวัล รางวัลละ 2 ที่นั่ง มูลค่ารางวัลละ 170,000 บาทมูลค่ารวม 850,000 บาท</p>
        <h6>ระยะเวลาแคมเปญ</h6>
        <p>19 มีนาคม 2567 – 31 พฤษภาคม 2567</p>
        <h6>กติกาการร่วมสนุก</h6>
        <ol style="padding-left: 16px;">
          <li>ซื้อ โออิชิขนาด 180มล. 350มล. 500มล. หรือชาคูลล์ซ่าขนาด 320มล. หรือจับใจขนาด 350มล. ครบ 1 ลัง รับ 1 สิทธิ์ ผ่าน Line Official Sermsuk Family เพื่อลุ้นรางวัลแพ็คเกจท่องเที่ยวญี่ปุ่นพร้อมเครื่องบินไปกลับ ประเทศญี่ปุ่น จำนวน จำนวน 5 รางวัล รางวัลละ 2 ที่นั่งมูลค่า 170,00 บาทต่อรางวัลรวม 850,000 บาท</li>
          <li>ทำการจับฉลากรางวัลครั้งเดียวจำนวน 5 รางวัล วันที่ 6 มิถุนายน 2567</li>
          <li>ระยะเวลาในการรับสิทธิ์ชิงรางวัล เริ่ม 19 มีนาคม 2567 – 31 พฤษภาคม 2567</li>
          <li>ผู้โชคดีต้องลงทะเบียนสมาชิกใน Line Official Sermsuk Family ตามวิธีการที่กำหนด และกดรับสิทธิ์ตามเงื่อนไขรายการ</li>
          <li>หมดเขตรับคูปอง 31 พฤษภาคม 2567</li>
          <li>ประกาศรายชื่อผู้โชคดีที่ไลน์ออฟฟิเชียล “Oishi Lucky Win” (@OishiLuckyWin)</li>
          <li>ผู้โชคดีที่ได้รับรางวัลมูลค่า 1,000 บาทขึ้นไป จะต้องชำระภาษี ณ ที่จ่าย 5% ของมูลค่ารางวัลที่ได้รับ</li>
          <li>ผู้โชคดีต้องติดต่อรับของรางวัลภายใน 30 วัน หลังจากประกาศผล มิฉะนั้นถือว่าสละสิทธิ์</li>
          <li>หากมีข้อพิพาท คำตัดสินของคณะกรรมการ ถือเป็นเด็ดขาดและสิ้นสุด </li>
        </ol>
      </div>
      <template #modal-footer="" >
        <div style="width:100%;justify-content:center" class="text-center">
          <button class="btn" @click="_confirm">ยอมรับและเข้าร่วมกิจกรรม</button>
        </div>
      </template>
    </b-modal>
    <b-modal id="confirm" hide-footer centered>
      <template v-slot:modal-header-close>
        <i class="close-modal icon-cancel-circled-outline" />
      </template>
      <div class="d-block text-center">
        <b>คุณได้รับสิทธิ์ลุ้นรางวัลเรียบร้อย</b>
      </div>
      <div class="text-center">
        <button class="btn" @click="_submit">ตกลง</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data: () => ({
    remain: 0,
    luckydraw:null,
    checkList: [],
    checkDetail: null,
    activeReceipt:null,
  }),
  async mounted() {
    window.loading(true);
    await this.fetchLuckydraw();
    await this.fetchReceipt();
    window.loading(false);
  },
  methods: {
    _click(receipt) {
      this.activeReceipt = receipt
      this.$bvModal.show("campaign");
    },
    async _confirm() {
      this.$bvModal.hide("campaign");
      window.loading(true);
      try {
        const res = await this.fetchJoin(this.activeReceipt.id)
        window.loading(false);
        switch (res.status) {
          case 200:
            this.$bvModal.show("confirm");
            break;
          default:
            this.Swal.fire({
              html: `กดรับสิทธิ์ไม่ได้ในขณะนี้<br>กรุณาเข้าใช้ใหม่ภายหลัง หรือติดต่อเจ้าหน้าที่`,
              icon: "warning",
            });
        }
      } catch (error) {
        window.loading(false);
        console.log(error);
        this.alertMsg("error");
      }
    },
    async _submit() {
      window.loading(true);
      await this.fetchLuckydraw();
      await this.fetchReceipt();
      window.loading(false);
      this.$bvModal.hide("confirm");
    },
    async fetchLuckydraw() {
      const res =  await fetch(`${process.env.VUE_APP_API_URL}/lucky-draws-campaign`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
      switch (res.status) {
        case 200: {
          let data = await res.json()
          this.luckydraw = data.luckydraw
          this.remain = data.my_point

          return;
        }
        case 204: {
          break;
        }
        case 401:
          console.log("case 401");
          break;
        default: {
          console.log("no case");
          break;
        }
      }
    },
    async fetchReceipt() {
      window.loading(true);
      if(this.luckydraw==null) return
      const res =  await fetch(`${process.env.VUE_APP_API_URL}/lucky-draws-campaign/receipts`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
      switch (res.status) {
        case 200: {
          let data = await res.json()
          this.checkList = data
          return;
        }
        case 401:
          console.log("case 401");
          break;
        default: {
          console.log("no case");
          throw "no case";
        }
        
      }
    },
    async fetchJoin(id) {
      const sendBody = {
        receiptId: id,
      };

      return await fetch(process.env.VUE_APP_API_URL + `/lucky-draws-campaign/${this.luckydraw.id}/join`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.$JWT}`,
          },
          body: JSON.stringify(sendBody),
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 4px;
  background-color: #002fb1;
  color: #fff;
  font-size: 14px;
}

.check-campaign-container {
  background-image: linear-gradient(167deg, #00c0ff, #98eeff 95%);
  // min-height: 100vh;
  padding: 30px 20px 10px;
  .remain-container {
    display: flex;
    border-radius: 8px;
    background-color: #002fb1;
    color: #fff;
    line-height: normal;
    padding: 24px;
    justify-content: space-between;
    margin: auto;
    span {
      font-size: 36px;
    }
  }
  .table-container {
    background: #fff;
    margin: 0 -20px -10px;
    .title {
      padding: 20px;
    }
    .table {
      width: 100%;
      thead {
        background: #efeff4;
        th {
          padding-left: 20px;
        }
      }
      tbody {
        font-size: 12px;
        tr {
          border-top: #e5e5ea 2px solid;
          &:nth-child(1) {
            border-top: none;
          }
        }
        td {
          padding: 12px 12px 10px;
        }
      }
    }
    .btn {
      border-radius: 4px;
      background-color: #002fb1;
      color: #fff;
      font-size: 12px;
      width: 100%;
    }
  }
  .blank-content {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      height: 180px;
    }
}
</style>